import { registerLocaleData } from '@angular/common';
import localeJa from '@angular/common/locales/ja';
import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling, withRouterConfig } from '@angular/router';
import { environment } from '@environment';
import { provideSlashkit } from '@twogate/slashkit';
import { provideSlashkitPlus } from '@twogate/slashkit-plus';

import { routes } from './app.routes';
import { ASSETS, LOCALE } from './config/assets';
import { slashkitConfig } from './slashkit.config';

registerLocaleData(localeJa);

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule, BrowserAnimationsModule),
    provideRouter(
      routes,
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      withRouterConfig({ paramsInheritanceStrategy: 'always' }),
    ),
    { provide: LOCALE_ID, useValue: 'ja-JP' },
    provideSlashkit(slashkitConfig, ASSETS, LOCALE),
    provideSlashkitPlus({
      channelId: environment.channelId,
    }),
  ],
};
