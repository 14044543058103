import { environment } from '@environment';
import { SlashkitConfig } from '@twogate/slashkit';
import { versions } from '@version';

export const slashkitConfig: SlashkitConfig = {
  environment: environment.name,
  production: environment.production,
  tenantId: environment.tenantId,
  firebaseConfig: environment.firebaseConfig,
  gtmContainerId: environment.gtmContainerId,
  versions,

  childProtect: {
    enableRegistrationGuard: true,
  },

  authProviders: {
    signUp: {
      phone: false,
      email: true,
      google: true,
      apple: true,
    },
    signIn: {
      phone: false,
      email: true,
      google: true,
      apple: true,
    },
  },
};
