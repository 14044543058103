import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SlashkitService } from '@twogate/slashkit';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  private slashkitService = inject(SlashkitService);

  ngOnInit() {
    this.slashkitService.initialize();
  }
}
