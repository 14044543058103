import { Environment } from './environment.type';

export const environment: Environment = {
  production: false,
  name: 'development',

  tenantId: 'polaris',
  channelId: 'polaris',

  firebaseConfig: {
    apiKey: 'AIzaSyBZTr8uXLSk1Yl_5YJ7wQmIjlQxHrNm4uw',
    authDomain: 'sgp-polaris.firebaseapp.com',
    projectId: 'sgp-polaris',
    storageBucket: 'sgp-polaris.appspot.com',
    messagingSenderId: '245033371224',
    appId: '1:245033371224:web:9d26eccb1f6f421954ec2d',
  },
};

export * from './environment.type';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
