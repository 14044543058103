import { Link, SlashkitAssets, SlashkitLocale } from '@twogate/slashkit';

const termLink: Link = {
  label: '利用規約',
  href: 'https://twogate.notion.site/780215b790ee4bfa83d3a3897b972f7d',
  type: 'external',
};

const policyLink: Link = {
  label: 'プライバシーポリシー',
  href: 'https://twogate.notion.site/38d3dd0f2c534e8784ab2b82fe4a3ee1',
  type: 'external',
};

const lawLink: Link = {
  label: '特定商取引法に基づく表示',
  href: 'https://twogate.notion.site/581b2d8241f94665a834af0f012cbdc0',
  type: 'external',
};

const contactLink: Link = {
  label: 'よくあるご質問・お問い合わせ',
  href: 'https://shop-assistance.com',
  type: 'external',
  addParams: true,
};

export const ASSETS: SlashkitAssets = {
  logoImage: {
    src: './assets/images/logo.png',
    originalWidth: 460,
    originalHeight: 150,
  },

  termLink,
  policyLink,
  lawLink,
  contactLink,

  navLinks: [
    {
      label: 'くじを探す',
      href: '/store/top',
      type: 'internal',
    },
    policyLink,
    termLink,
    lawLink,
    contactLink,
  ],
};

export const LOCALE: SlashkitLocale = {
  common: {
    appName: 'Slashkit Plus',
    appFullName: 'Slashkit Plus（デモ）',
    copyright: 'Slashkit Plus',
    hashTag: 'スラギフプラス',
  },
};
