import { Routes } from '@angular/router';
import { authGuard, MainLayoutPage, secondaryRoutes } from '@twogate/slashkit';
import { plusRouteChildren } from '@twogate/slashkit-plus';

export const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    component: MainLayoutPage,
    children: [...plusRouteChildren],
  },
  ...secondaryRoutes,
];
