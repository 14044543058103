import { Provider } from '@angular/core';

import { SlashkitPlusConfig } from './config/config.type';
import { provideStoreSlug } from './providers/store-slug';
import { storeUrlManagerProvider } from './services/store-url-manager.service';

export function provideSlashkitPlus(config: SlashkitPlusConfig): Provider {
  return [
    provideStoreSlug,
    storeUrlManagerProvider,
    {
      provide: SlashkitPlusConfig,
      useValue: config,
    },
  ];
}
