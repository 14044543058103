import { Routes } from '@angular/router';
import { appRouteChildren, authGuard, EmptyPage, MainLayoutPage, redirectWithQueryParams } from '@twogate/slashkit';

export const plusRouteChildren: Routes = [
  {
    path: '',
    component: EmptyPage,
    canActivate: [redirectWithQueryParams('/store')],
  },
  {
    path: 'store',
    loadChildren: () => import('./pages/store/store.routing'),
  },
  {
    path: 'preview',
    loadChildren: () => import('./pages/preview/preview.routing'),
  },
  ...appRouteChildren.filter((route) => !['preview', 'store', 'notification'].includes(route.path)),
];

export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    component: MainLayoutPage,
    children: plusRouteChildren,
  },
];
