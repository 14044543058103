import { ClassProvider, Injectable } from '@angular/core';
import { AbstractStoreUrlManager, StoreRouteType } from '@twogate/slashkit';

@Injectable()
export class StoreUrlManager extends AbstractStoreUrlManager {
  transform(id: string, type: StoreRouteType) {
    switch (type) {
      case 'store_top':
        return '/store';
      case 'store_lottery_list':
        return '/store/lottery';
      case 'store_limited_lottery':
        return '/store/limited';
      case 'store_lottery-not-found':
        return `/store/lottery-not-found`;
      case 'store_notification_list':
        return '/store/notification';
      default:
        return 'error/not-found';
    }
  }
}

export const storeUrlManagerProvider: ClassProvider = {
  provide: AbstractStoreUrlManager,
  useClass: StoreUrlManager,
};
