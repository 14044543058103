import { FactoryProvider } from '@angular/core';
import { STORE_SLUG } from '@twogate/slashkit';

import { SlashkitPlusConfig } from '../config/config.type';

export const provideStoreSlug: FactoryProvider = {
  provide: STORE_SLUG,
  useFactory: (config: SlashkitPlusConfig) => {
    return config.channelId;
  },
  deps: [SlashkitPlusConfig],
};
